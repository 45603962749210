import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import Page from 'components/page';

import {
  LOGIN_ROUTE, LANDING_PAGE, REGISTRATION_START, CONTACT_US,
  SUPPORT_PROBLEM, ACCOUNT_ROUTE, SUBSCRIPTION_ROUTE, HOME_LOGGED_IN_ROUTE,
} from 'constants/navigation';
import {
  LOGIN_ICON, HOME_NAV, REGISTER_ICON, EMAIL_NAV,
  BUG, ACCOUNT_NAV, SUBSCRIPTION_NAV,
} from 'constants/font-awesome';

import { isAuthenticated } from 'services/authentication-service';
import { hasSubscription } from 'state/selectors/has-subscription';

import useStyles from 'apputil/page-styles';

import { Typography } from '@material-ui/core';

const LazyContactBanner = loadable(() => import('components/banners/contact'));
const LazyContactBannerAuth = loadable(() => import('components/banners/in-app-banners/contact'));
const LazyContactUsView = loadable(() => import('views/contact-us'));
const LazyMessenger = loadable(() => import('components/facebook-messenger'));
const LazyOverrideFooter = loadable(() => import('components/footer/override-footer'));

const loggedOutNav = [
  { route: LANDING_PAGE, icon: HOME_NAV, message: 'Home' },
  { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
  { route: LOGIN_ROUTE, icon: LOGIN_ICON, message: 'Login' },
  { route: REGISTRATION_START, icon: REGISTER_ICON, message: 'Register' },
];

const loggedInHasSubNav = [
  { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
  { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
  { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
  { route: ACCOUNT_ROUTE, icon: ACCOUNT_NAV, message: 'Account' },
];

const loggedInNoSubNav = [
  { route: HOME_LOGGED_IN_ROUTE, icon: HOME_NAV, message: 'Home' },
  { route: CONTACT_US, icon: EMAIL_NAV, message: 'Contact' },
  { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
  { route: SUBSCRIPTION_ROUTE, icon: SUBSCRIPTION_NAV, message: 'Subscription' },
];

const SupendedContactUsPage = ({ loading }) => {
  const styles = useStyles();

  const [navItems, setNavItems] = useState(loggedOutNav);

  const { cmsJson: { pages: { contactUs } } } = useStaticQuery(
    graphql`
      query  { cmsJson { pages { contactUs { title message } } } }`,
  );

  const authenticated = isAuthenticated();

  useEffect(() => {
    const getSubScriptionNav = async () => {
      const sub = await hasSubscription();
      setNavItems(sub ? loggedInHasSubNav : loggedInNoSubNav);
    };

    if (authenticated) {
      getSubScriptionNav();
    } else {
      setNavItems(loggedOutNav);
    }

    // eslint-disable-next-line
  }, []);

  const { title, message } = contactUs;

  return (
      <Page
        title="Contact"
        description={message}>
        {authenticated ? <LazyContactBannerAuth/> : <LazyContactBanner/>}
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
          {title}
        </Typography>
        <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
          {message}
        </Typography>
        <LazyContactUsView loading={loading}/>
        <LazyMessenger/>
        <LazyOverrideFooter
          navItems={navItems}
          currentItem="Contact"
          />
      </Page>
  );
};

SupendedContactUsPage.propTypes = { loading: bool };

export default SupendedContactUsPage;
